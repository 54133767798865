import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import CustomModal from "../components/modal/CustomModal";
import { useConnectionsRequest } from "../services/apis/connections/useConnectionsRequest";
import Text from "../components/Text";
import ActionText from "../components/ActionText";
import Column from "../components/Column";
import LoadingSpinner from "../components/LoadingSpinner";

export interface ICompany {
    _id: string;
    publicIdentifier: string;
    specialties: string[];
    type: string[];
    createdAt: string;
    updatedAt: string;
    aboutUs: string;
    address: string;
    city: string;
    countryCode: string;
    crunchbaseUrl: string;
    employeesNum: number;
    founded: number;
    headquarters: string;
    imageUrl: string;
    industry: string;
    linkedinUrl: string;
    name: string;
    size: string;
    socialFollowers: number;
    state: string;
    website: string;
}  

const CompanyOverviewModal: FunctionComponent<{companyId: any}> = (companyId) => {
    const { getCompanyOverview } = useConnectionsRequest()

    const [company, setCompany] = useState<ICompany | null>(null)

    const getCompanyOverviewHandler = async () => {
        const result = await getCompanyOverview(companyId)
        if(result) setCompany(result)
    }

    useEffect(() => {
        getCompanyOverviewHandler()
    }, [])

    const openLink = (url: string) => {
        window.open(url, "_blank");
      };

    return (
        <CustomModal
            $center
            headingText={'Company Overview'}
            closeButton={true}
            bodyPadding={'0 24px 0px 24px;'}
        > 
            <Container gap="10px">
                {/* Company Name */}
                <Column>
                    <Text $label>Company Name:</Text>
                    <StyledColumnDark>
                        {company ? <Text>{company.name}</Text> : <LoadingSpinner />}
                    </StyledColumnDark>
                </Column>

                {/* Industry & Size */}
                <Column>
                    <Text $label>Industry & Size:</Text>
                    <StyledColumnDark>
                        {company ? <Text>{company.industry} | {company.size} employees</Text> : <LoadingSpinner />}
                    </StyledColumnDark>
                </Column>

                {/* Location */}
                <Column>
                    <Text $label>Location:</Text>
                    <StyledColumnDark>
                        {company ? <Text>{company.headquarters}, {company.countryCode?.toUpperCase()}</Text> : <LoadingSpinner />}
                    </StyledColumnDark>
                </Column>

                {/* About Us */}
                <Column>
                    <Text $label>About Us:</Text>
                    <StyledColumnDark>
                        {company ? <Text>{company.aboutUs}</Text> : <LoadingSpinner />}
                    </StyledColumnDark>
                </Column>

                {/* Website Link */}
                <Column>
                    <Text $label>Website:</Text>
                    <StyledColumnDark>
                        {company ? (
                            <ActionText onClickHandler={() => openLink(company.website)}>
                                {company.website}
                            </ActionText>
                        ) : (
                            <LoadingSpinner />
                        )}
                    </StyledColumnDark>
                </Column>

                {/* Social Links */}
                <Column>
                    <Text $label>LinkedIn:</Text>
                    <StyledColumnDark>
                        {company ? (
                            <ActionText onClickHandler={() => openLink(company.linkedinUrl)}>
                                LinkedIn Profile
                            </ActionText>
                        ) : (
                            <LoadingSpinner />
                        )}
                    </StyledColumnDark>
                </Column>
            </Container>
        </CustomModal>
    )
}

const Container = styled(Column)`
    border-radius: 5px;
`
const StyledColumnDark = styled(Column)<{$minHeight?: boolean}>`
    max-height: 200px;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: ${styles.colors.black100};
    overflow: auto;
`

export default CompanyOverviewModal